import { ComponentRef, EditorSDK, ElementData } from '@wix/platform-editor-sdk';
import { PlanListWidgetRole, PlanWidgetRole, WidgetRole } from '@wix/pricing-plans-common/blocks';
import { EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';
import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { PLAN_LIST_PRESETS, PLAN_LIST_PRESET_CATEGORIES } from '../../blocks-widgets-editor/layout/plan-list';
import { BLOCKS_PLAN_LIST_HELP_ARTICLE_ID } from '../../constants';
import { planListThumbnailsClassic, planListThumbnailsStudio } from '../../layout-thumbnails';
import { openElementsPanel } from '../../utils/open-elements-panel';
import { getRootWidget } from '../../utils/widget';
import { openPlanElementsPanel } from '../Plan/editor.controller';
import { getCategoriesData, getElementsData } from '../Plan/elements-panel-data';
import { openDisclaimerLayoutPanel } from './panels/DisclaimerLayout/open-disclaimer-layout-panel';
import { openPlanListLayoutPanel } from './panels/Layout/open-plan-list-layout-panel';
import { openPlanLayoutPanel } from './panels/PlanLayout/open-plan-layout-panel';
import { openPlanSettingsPanel } from './panels/PlanSettings/open-plan-settings-panel';
import { openPlanListSettingsPanel } from './panels/Settings/open-plan-list-settings-panel';
import { openSubtitleLayoutPanel } from './panels/SubtitleLayout/open-subtitle-layout-panel';
import { openTitleLayoutPanel } from './panels/TitleLayout/open-title-layout-panel';

const UNSELECTABLE_ELEMENTS = [
  PlanListWidgetRole.PlansInfoState,
  PlanListWidgetRole.PlanList,
  PlanListWidgetRole.PlanListStates,
  PlanListWidgetRole.Header,
  PlanListWidgetRole.TitleContainer,
  PlanListWidgetRole.DisclaimerContainer,
  PlanListWidgetRole.PlanListItem,
  PlanListWidgetRole.PlanVariantBox,
  PlanListWidgetRole.PlanListItemContainer,
  PlanListWidgetRole.PlanVariantDefaultState,
  PlanListWidgetRole.PlanVariantHighlightedState,
  PlanListWidgetRole.PlanVariantCustomState,
];

const PLAN_VARIANT_ELEMENTS = [
  PlanListWidgetRole.PlanVariantDefaultWidget,
  PlanListWidgetRole.PlanVariantHighlightedWidget,
  PlanListWidgetRole.PlanVariantCustomWidget,
] as const;

const planVariantLabelKey = {
  [PlanListWidgetRole.PlanVariantDefaultWidget]: 'blocks.plan-list-settings-panel.default-style',
  [PlanListWidgetRole.PlanVariantHighlightedWidget]: 'blocks.plan-list-settings-panel.highlighted-style',
  [PlanListWidgetRole.PlanVariantCustomWidget]: 'blocks.plan-list-settings-panel.custom-style',
};

export const getWidgetManifest: GetWidgetManifestFn = (builder, editorSDK, flowAPI) => {
  builder.set({ displayName: flowAPI.translations.t('blocks.plan-list.label') });

  builder
    .gfpp()
    .set('mainAction1', {
      onClick: (e) => openPlanListSettingsPanel({ editorSDK, flowAPI, componentRef: e.detail.componentRef }),
      label: flowAPI.translations.t('blocks.plan-list-settings.title'),
    })
    .set('add', {
      onClick: (e) => openPlanListElementsPanel({ editorSDK, flowAPI, widgetRef: e.detail.componentRef }),
    })
    .set('layout', {
      onClick: (e) => openPlanListLayoutPanel({ editorSDK, componentRef: e.detail.componentRef }),
    })
    .set('connect', { behavior: 'HIDE' })
    .set('help', { id: BLOCKS_PLAN_LIST_HELP_ARTICLE_ID });

  builder
    .gfpp('mobile')
    .set('layout', {
      onClick: (e) => openPlanListLayoutPanel({ editorSDK, componentRef: e.detail.componentRef }),
    })
    .set('design', { behavior: 'DEFAULT' })
    .set('presets', { behavior: 'HIDE' });

  builder.behavior().set({ resizable: true, duplicatable: true });

  UNSELECTABLE_ELEMENTS.forEach((element) =>
    builder.configureConnectedComponents(element, (elementBuilder) => {
      elementBuilder.behavior().set({ closed: { hideFromHierarchy: true, selectable: false } });
    }),
  );

  builder.configureWidgetPresets((presetsBuilder) => {
    presetsBuilder.setCategories(
      PLAN_LIST_PRESET_CATEGORIES.map((category) => ({
        id: category.id,
        name: flowAPI.translations.t(category.nameKey),
      })),
    );
    const thumbnails = flowAPI.environment.isClassicEditor ? planListThumbnailsClassic : planListThumbnailsStudio;
    presetsBuilder.setPresets(
      PLAN_LIST_PRESETS.map((preset) => ({
        ...preset,
        src: thumbnails[preset.id],
      })),
    );
  });

  builder.configureConnectedComponents(PlanListWidgetRole.Title, (disclaimerBuilder) => {
    disclaimerBuilder
      .gfpp()
      .set('layout', {
        onClick: async (e) =>
          openTitleLayoutPanel({ editorSDK, componentRef: await getRootWidget(editorSDK, e.detail.componentRef) }),
      })
      .set('connect', { behavior: 'HIDE' });
  });

  builder.configureConnectedComponents(PlanListWidgetRole.SubtitleContainer, (headerBuilder) => {
    headerBuilder.behavior().set({ closed: { selectable: false, hideFromHierarchy: true } });
  });

  builder.configureConnectedComponents(PlanListWidgetRole.Subtitle, (disclaimerBuilder) => {
    disclaimerBuilder
      .gfpp()
      .set('layout', {
        onClick: async (e) =>
          openSubtitleLayoutPanel({ editorSDK, componentRef: await getRootWidget(editorSDK, e.detail.componentRef) }),
      })
      .set('connect', { behavior: 'HIDE' });
  });

  builder.configureConnectedComponents(PlanListWidgetRole.Disclaimer, (disclaimerBuilder) => {
    disclaimerBuilder
      .gfpp()
      .set('layout', {
        onClick: async (e) =>
          openDisclaimerLayoutPanel({ editorSDK, componentRef: await getRootWidget(editorSDK, e.detail.componentRef) }),
      })
      .set('connect', { behavior: 'HIDE' });
  });
  const t = flowAPI.translations.t;

  PLAN_VARIANT_ELEMENTS.forEach((widget) => {
    builder.configureConnectedComponents(widget, (widgetVariantBuilder) => {
      widgetVariantBuilder.set({
        displayName: t(planVariantLabelKey[widget]),
      });
      widgetVariantBuilder.behavior().set({ closed: { selectable: true, hideFromHierarchy: false } });
      widgetVariantBuilder
        .gfpp()
        .set('mainAction1', {
          label: flowAPI.translations.t('blocks.plan-list.plan.settings.title'),
          onClick: (e) => {
            openPlanSettingsPanel({
              editorSDK,
              componentRef: e.detail.componentRef,
              flowAPI,
            });
          },
        })
        .set('layout', {
          onClick: (e) => {
            openPlanLayoutPanel({
              editorSDK,
              componentRef: e.detail.componentRef,
              flowAPI,
            });
          },
        })
        .set('add', {
          onClick: (e) => {
            openPlanElementsPanel({
              editorSDK,
              subtitle: flowAPI.translations.t('blocks.elements-panel.plan-list-card-subtitle'),
              widgetRef: e.detail.componentRef,
              flowAPI,
              data: {
                categories: getCategoriesData(flowAPI.translations.t),
                elements: getElementsData(flowAPI.translations.t).map((element) => {
                  if (element.identifier.role === PlanWidgetRole.PlanName) {
                    return updateElementDataRole(element, PlanWidgetRole.PlanNameContainer);
                  }

                  if (element.identifier.role === PlanWidgetRole.Description) {
                    return updateElementDataRole(element, PlanWidgetRole.DescriptionContainer);
                  }

                  return element;
                }),
              },
            });
          },
        })
        .set('help', { id: BLOCKS_PLAN_LIST_HELP_ARTICLE_ID })
        .set('connect', { behavior: 'HIDE' });
      widgetVariantBuilder.gfpp('mobile').set('layout', {
        onClick: async (e) => {
          openPlanLayoutPanel({
            editorSDK,
            componentRef: e.detail.componentRef,
            flowAPI,
          });
        },
      });
    });
  });
};

function openPlanListElementsPanel(params: {
  editorSDK: EditorSDK;
  widgetRef: ComponentRef;
  flowAPI: EditorScriptFlowAPI;
}) {
  const { editorSDK, widgetRef, flowAPI } = params;
  const t = flowAPI.translations.t;
  return openElementsPanel({
    editorSDK,
    widgetRef,
    subtitle: t('blocks.elements-panel.plan-list-subtitle'),
    data: {
      categories: [
        {
          id: 'plan-info',
          title: t('blocks.plan-list-elements-panel.plan-info'),
        },
      ],
      elements: [
        {
          label: t('blocks.plan-list-elements-panel.title'),
          identifier: { role: PlanListWidgetRole.Title },
          categoryId: 'plan-info',
          index: 0,
        },
        {
          label: t('blocks.plan-list-elements-panel.subtitle'),
          identifier: { role: PlanListWidgetRole.Subtitle },
          categoryId: 'plan-info',
          index: 1,
        },
        {
          label: t('blocks.plan-list-elements-panel.plan-list'),
          identifier: { role: PlanListWidgetRole.PlanList },
          categoryId: 'plan-info',
          index: 2,
        },
        {
          label: t('blocks.plan-list-elements-panel.disclaimer'),
          identifier: { role: PlanListWidgetRole.Disclaimer },
          categoryId: 'plan-info',
          index: 3,
        },
      ],
    },
  });
}

function updateElementDataRole(element: ElementData, newRole: WidgetRole): ElementData {
  return {
    ...element,
    identifier: {
      ...element.identifier,
      role: newRole,
    },
  };
}
